/* eslint-disable class-methods-use-this */
import React from 'react'
import styled, { withTheme } from 'styled-components'
import { Flex } from 'rebass'
import markerImage from '../../images/marker.svg'

const Wrapper = styled.div`
  ${(props) => props};
`

const StyledNavigateAnchor = styled.a`
  display: block;
  margin-top: -1rem;
  padding: 0.5rem 1rem;
  background-color: ${(props) => props.theme.colors.text};
  color: #fff;
  justify-self: center;
  text-decoration: none;
  z-index: 1;
`

class SeznamMapa extends React.Component {
  constructor(props) {
    super(props)

    this.mapNode = React.createRef()
    this.createMap = this.createMap.bind(this)
    this.markerLayer = null
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.loadScript()
    }
  }

  loadScript() {
    const script = document.createElement('script')
    script.src = 'https://api.mapy.cz/loader.js'
    script.async = true
    window.document.head.appendChild(script)

    script.addEventListener('load', () => {
      if (typeof window.Loader !== 'undefined') {
        window.Loader.async = true
        window.Loader.load(null, null, this.createMap)
      }
    })
  }

  addMarkers() {
    if (this.props.markers && this.props.markers.length > 0) {
      this.props.markers.forEach((m) => {
        const markerPosition = window.SMap.Coords.fromWGS84(
          parseFloat(m.lng),
          parseFloat(m.lat),
        )
        const markerDiv = window.JAK.mel('div')
        const markerImg = window.JAK.mel('img', {
          src: markerImage,
          width: 30,
          height: 30,
        })
        markerDiv.style.width = '30px'
        markerDiv.appendChild(markerImg)

        const marker = new window.SMap.Marker(markerPosition, null, {
          url: markerDiv,
          size: [30, 30],
          anchor: { left: 15, top: 30 },
          title: m.title || 'Chaloupka',
        })

        if (m.title) {
          const card = new window.SMap.Card()
          let body = ''
          card.getHeader().innerHTML = `<strong style="font-size: 1.25rem">${m.title}</strong>`

          if (m.address) {
            body += m.address
          }

          if (m.link) {
            body += `<a href="${m.link}" target="_blank" rel="noopener noreferrer">Navigovat</a>`
          }

          card.getBody().innerHTML = body

          marker.decorate(window.SMap.Marker.Feature.Card, card)
        }

        this.markerLayer.addMarker(marker)
      })
    }
  }

  createMap() {
    const center = this.props.center
      ? window.SMap.Coords.fromWGS84(
          parseFloat(this.props.center.lng),
          parseFloat(this.props.center.lat),
        )
      : window.SMap.Coords.fromWGS84(
          parseFloat(this.props.markers[0].lng),
          parseFloat(this.props.markers[0].lat),
        )
    const mapa = new window.SMap(
      window.JAK.gel('mapa'),
      center,
      this.props.zoom || 13,
    )

    const mouseProps = window.SMap.MOUSE_PAN || window.SMap.MOUSE_ZOOM
    const mouseControl = new window.SMap.Control.Mouse(mouseProps)
    const zoomProps = { showZoomMenu: true }
    const zoomControl = new window.SMap.Control.Zoom(null, zoomProps)
    this.markerLayer = new window.SMap.Layer.Marker()

    mapa.addDefaultLayer(window.SMap.DEF_BASE).enable()

    this.addMarkers()

    mapa.addControl(mouseControl)
    mapa.addControl(zoomControl, { right: '8px', top: '9px' })
    mapa.addLayer(this.markerLayer)
    this.markerLayer.enable()
  }

  render() {
    return (
      <Wrapper {...this.props.containerStyle}>
        {this.props.naviURL && this.props.up && (
          <Flex justifyContent="center">
            <StyledNavigateAnchor
              href={this.props.naviURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Navigovat
            </StyledNavigateAnchor>
          </Flex>
        )}

        <div
          id="mapa"
          ref={this.mapNode}
          style={{ width: '100%', height: '100%' }}
        />

        {this.props.naviURL && !this.props.up && (
          <Flex justifyContent="center">
            <StyledNavigateAnchor
              href={this.props.naviURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Navigovat
            </StyledNavigateAnchor>
          </Flex>
        )}
      </Wrapper>
    )
  }
}

export default withTheme(SeznamMapa)
