/* eslint-disable react/style-prop-object */
import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Box, Flex } from "rebass";
import Layout from "../components/Layout";
import { Section } from "../components/Section";
import Container from "../components/Container";
import SocialLinks from "../components/SocialLinks";
import SeznamMapa from "../components/Map";
import Podpis from "../images/podpis.inline.svg";

const photoSize = 280;

const StyledPhotoWrapper = styled.div`
  width: ${photoSize}px;
  height: ${photoSize}px;
  border-radius: ${photoSize}px;
  border: 4px solid ${(props) => props.theme.colors.accent};
  overflow: hidden;
`;

const StyledPodpis = styled(Podpis)`
  max-width: 240px;
  max-height: 110px;

  @media screen and (min-width: 768px) {
    position: relative;
    top: -3rem;
    left: 15rem;
  }
`;

export const PageContactTemplate = ({
  title,
  content,
  photo,
  socialNetworks,
}) => (
  <Section>
    <Container>
      <Flex flexDirection={["column", "row"]}>
        <Box width={[1, 1 / 2]}>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }} />

          <SocialLinks
            links={socialNetworks}
            color="text"
            iconSize="48px"
            justifyContent="flex-start"
          />
        </Box>

        <Flex flexDirection="column" alignItems="center">
          <StyledPhotoWrapper>
            <Img loading="eager" fixed={photo.childImageSharp.fixed} alt="" />
          </StyledPhotoWrapper>

          <StyledPodpis />
        </Flex>
      </Flex>
    </Container>
  </Section>
);

PageContactTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};

const PageContact = ({ data }) => {
  const { wordpressPage: page, site, photo, locations } = data;
  const { acf } = page;
  const {
    social_network: socialNetworks,
  } = data.allWordpressAcfOptions.edges[0].node.options;
  console.log(locations);

  const markers = locations.edges.map(({ node }) => {
    if (node.acf.map.lat && node.acf.map.lng) {
      return {
        lat: node.acf.map.lat,
        lng: node.acf.map.lng,
        title: node.title,
        address: node.acf.address || null,
        link: node.acf.navi_url || null,
      };
    }
  });

  return (
    <Layout>
      <Helmet title={`${page.title} | ${site.siteMetadata.title}`} />
      <PageContactTemplate
        title={page.title}
        content={page.content}
        photo={photo}
        socialNetworks={socialNetworks}
      />
      <SeznamMapa
        markers={markers}
        zoom={12}
        center={{
          lat: 49.573432,
          lng: 18.377257,
        }}
        style="mapbox://styles/mapbox/streets-v9"
        containerStyle={{
          height: "50vh",
          width: "100vw",
        }}
      />
    </Layout>
  );
};

PageContact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PageContact;

export const pageQuery = graphql`
  query PageContact($id: String!) {
    photo: file(relativePath: { eq: "foto-kontakt.jpg" }) {
      childImageSharp {
        fixed(quality: 80, width: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    locations: allWordpressWpMphbRoomType(
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          ...RoomTypesListFields
          acf {
            navi_url
            address
            map {
              lat
              lng
            }
          }
        }
      }
    }
    allWordpressAcfOptions {
      edges {
        node {
          options {
            social_network {
              name
              icon {
                label
                value
              }
              url
            }
          }
        }
      }
    }
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
